import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import tw, { css, theme } from 'twin.macro'
import Button from './button'
import Heading from './heading'
import Image from './image'
import Text from './text'

const UseCaseCards = ({ useCases }) => {
  const [activeCard, setActiveCard] = useState(null)

  const handleMouseEnter = debounce((_, index) => {
    setActiveCard(index)
  }, 50)

  const handleMouseLeave = debounce(() => {
    setActiveCard(null)
  }, 50)

  return (
    <div css={tw`flex flex-col space-y-16 lg:(flex-row space-x-8 space-y-0)`}>
      {useCases.map((c, i) => {
        const cardRef = useRef(null)
        useEffect(() => {
          const query = `(min-width: ${theme`screens.lg`})`
          ScrollTrigger.matchMedia({
            [query]: () => {
              gsap.to(cardRef.current, {
                y: i % 2 === 0 ? -64 : -128,
                ease: 'none',
                scrollTrigger: {
                  trigger: cardRef.current,
                  start: 'top bottom',
                  end: 'top center-=128px',
                  scrub: true,
                },
              })
            },
          })
        }, [])
        return (
          <div
            key={i}
            css={[
              css`
                ${tw`relative flex flex-col items-start justify-between px-8 py-16 flex-equal`}
                transition: top 300ms ease, background-color 300ms ease;
              `,
              i % 2 === 0 ? tw`lg:top-0` : tw`lg:top-16`,
              activeCard === i ? tw`bg-secondary-200` : tw`bg-secondary-100`,
              activeCard === i && (i % 2 === 0 ? tw`lg:-top-2` : tw`lg:top-14`),
            ]}
            ref={cardRef}
            onMouseEnter={(e) => handleMouseEnter(e, i)}
            onMouseLeave={(e) => handleMouseLeave(e, i)}
            onFocus={(e) => handleMouseEnter(e, i)}
          >
            <div css={tw`absolute w-16 right-12 -top-10`}>
              <Image image={c.image} />
            </div>
            <div>
              <Heading content={c.title} headingType="h4" style={tw`font-bold`} />
              <Text
                content={c.descriptionNode}
                style={[tw`mt-4 text-opacity-60`, activeCard === i && tw`text-primary-500`]}
                textType="body-base"
              />
            </div>
            {c.callToAction && <Button
              label={c.callToAction.label}
              link={c.callToAction.link}
              size="xs"
              type="primary"
              theme={activeCard === i ? 'navy-navy' : 'transparent-transparent'}
              style={tw`mt-10 border border-primary-500`}
            />}
          </div>
        )
      })}
    </div>
  )
}

UseCaseCards.propTypes = {
  useCases: PropTypes.arrayOf(PropTypes.object),
}

export default UseCaseCards
