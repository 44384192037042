import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, ImageType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Image from './image'

const themeToStyle = (theme) => {
  switch (theme) {
    case 'white':
      return tw`text-white`
    case 'cyan':
      return tw`text-secondary-500`
    case 'navy':
    default:
      return tw`text-primary-500`
  }
}

const FullImageHero = ({
  style,
  imageStyle,
  contentStyle,
  image,
  imageMobile,
  title,
  description,
  callToAction,
  theme,
  buttonTheme,
  extended,
}) => {
  const textColor = useMemo(() => themeToStyle(theme))

  return (
    <div
      css={[
        css`
          ${tw`relative bg-transparent`}
          ${extended && tw`-mt-18 lg:(-mt-20 pt-20 mb-52)`}
          strong {
            ${tw`font-medium`}
          }
        `,
        style,
      ]}
    >
      <div css={[global`layout.container`, global`layout.grid`, tw`relative`]}>
        <div
          css={[
            tw`relative col-span-4 order-1 pt-full mt-24
            md:col-span-8 lg:(order-none col-start-3 col-span-10 pt-ratio-4/3 mt-10 -mb-52 -mr-container-lg)
            xl:(pt-ratio-16/10 col-end-17 -mr-container-xl) xxl:(-mr-container-xxl)`,
            imageStyle,
          ]}
        >
          <Image
            image={image}
            imageMobile={imageMobile}
            style={tw`absolute inset-0 object-cover`}
          />
        </div>
        <div
          css={[tw`flex flex-col items-start col-span-4 md:col-span-8 lg:absolute`, contentStyle]}
        >
          <Heading
            headingType="h1"
            content={title}
            style={[
              tw`mt-38 xl:mt-52`,
              css`
                p {
                  ${tw`font-light`}
                  ${textColor}
                }
                span {
                  ${tw`font-medium`}
                }
              `,
            ]}
          />
          <Heading
            headingType="h4"
            content={description}
            style={[textColor, tw`mt-8 font-normal`]}
          />
          {callToAction && (
            <Button
              type="primary"
              size="sm"
              theme={buttonTheme}
              label={callToAction.label}
              link={callToAction.link}
              style={tw`mt-12`}
            />
          )}
        </div>
      </div>
    </div>
  )
}

FullImageHero.defaultProps = {
  theme: 'navy',
  buttonTheme: 'navy-white',
}

FullImageHero.propTypes = {
  style: StyleType,
  imageStyle: StyleType,
  contentStyle: StyleType,
  image: ImageType,
  imageMobile: ImageType,
  title: HTMLType,
  description: HTMLType,
  callToAction: LinkType,
  theme: PropTypes.oneOf(['white', 'navy', 'cyan']),
  buttonTheme: PropTypes.string,
  extended: PropTypes.bool,
}

export default FullImageHero
